import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import formattedDate from "~/dateHelpers"
import { ces_tool_path } from "~/routes"
import api from "~/services/api"
import DashboardWidget from "~/views/shared/DashboardWidget"

const CESToolsRequiringApprovalWidget = ({ teamId }) => {
  const { isLoading, data: cesToolsRequiringApproval } = useQuery({
    queryKey: ["cesToolsRequiringApproval", teamId],
    queryFn: () => api.supervisoryTeams.cesToolsRequiringApproval.get(teamId),
  })

  if (isLoading) {
    return (
      <DashboardWidget title="CES Tools Requiring Approval">
        <div className="text-center p-3">
          <img width="50px" height="50px" src={spinner} alt="Loading..." />
        </div>
      </DashboardWidget>
    )
  } else if (cesToolsRequiringApproval === null) {
    return (
      <DashboardWidget title="CES Tools Requiring Approval">
        <DashboardWidget.Error text="Sorry, something went wrong." />
      </DashboardWidget>
    )
  } else if (cesToolsRequiringApproval.length === 0) {
    return (
      <DashboardWidget title="CES Tools Requiring Approval">
        <DashboardWidget.Success text="You have no CES Tools requiring approval" />
      </DashboardWidget>
    )
  } else {
    return (
      <DashboardWidget title="CES Tools Requiring Approval">
        <DashboardWidget.BigNumber
          number={cesToolsRequiringApproval.length}
          description={`${pluralize("CES Tools", cesToolsRequiringApproval.length, false)} requiring approval`}
        />
        <div className="border-top overflow-auto px-3" style={{ maxHeight: "385px" }}>
          <table className="table table-sm mt-2">
            <thead>
              <tr>
                <th className="text-left border-0 font-weight-normal text-muted col-4">Date</th>
                <th className="text-left border-0 font-weight-normal text-muted col-4">Care Manager</th>
                <th className="text-right border-0 font-weight-normal text-muted col-4">Patient</th>
              </tr>
            </thead>
            <tbody>
              {cesToolsRequiringApproval.map((tool) => {
                return (
                  <tr key={tool.id}>
                    <td className="text-left">{formattedDate(tool.startDate)}</td>
                    <td className="text-left">{tool.creatorName}</td>
                    <td className="text-right">
                      <a href={ces_tool_path(tool.id)}>{tool.chartName}</a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </DashboardWidget>
    )
  }
}

CESToolsRequiringApprovalWidget.propTypes = {
  teamId: PropTypes.number.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <CESToolsRequiringApprovalWidget {...props} />
    </QueryClientProvider>
  )
}
export default Provider
