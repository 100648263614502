import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import spinner from "images/spinner.svg"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import formattedDate from "~/dateHelpers"
import { care_plan_path } from "~/routes"
import api from "~/services/api"
import DashboardWidget from "~/views/shared/DashboardWidget"

const CarePlanReviewRequestsWidget = ({ teamId }) => {
  const { isLoading, data: carePlanReviewRequests } = useQuery({
    queryKey: ["carePlanReviewRequests", teamId],
    queryFn: () => api.supervisoryTeams.pendingCarePlanReviewRequests.get(teamId),
  })

  if (isLoading) {
    return (
      <DashboardWidget title="Care Plan Review Requests">
        <div className="text-center p-3">
          <img width="50px" height="50px" src={spinner} alt="Loading..." />
        </div>
      </DashboardWidget>
    )
  } else if (carePlanReviewRequests === null) {
    return (
      <DashboardWidget title="Care Plan Review Requests">
        <DashboardWidget.Error text="Sorry, something went wrong." />
      </DashboardWidget>
    )
  } else if (carePlanReviewRequests.length === 0) {
    return (
      <DashboardWidget title="Care Plan Review Requests">
        <DashboardWidget.Success text="You have no requested care plan reviews." />
      </DashboardWidget>
    )
  } else {
    return (
      <DashboardWidget title="Care Plan Review Requests">
        <DashboardWidget.BigNumber
          number={carePlanReviewRequests.length}
          description={`requested care plan ${pluralize("reviews", carePlanReviewRequests.length, false)}`}
        />
        <div className="border-top overflow-auto px-3" style={{ maxHeight: "385px" }}>
          <table className="table table-sm mt-2">
            <thead>
              <tr>
                <th className="text-left border-0 font-weight-normal text-muted col-4">Date</th>
                <th className="text-left border-0 font-weight-normal text-muted col-4">Care Manager</th>
                <th className="text-right border-0 font-weight-normal text-muted col-4">Patient</th>
              </tr>
            </thead>
            <tbody>
              {carePlanReviewRequests.map((cpReviewRequest) => {
                return (
                  <tr key={cpReviewRequest.id}>
                    <td className="text-left">{formattedDate(cpReviewRequest.createdAt)}</td>
                    <td className="text-left">{cpReviewRequest.creatorName}</td>
                    <td className="text-right">
                      <a href={care_plan_path(cpReviewRequest.carePlanId)}>{cpReviewRequest.chartName}</a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </DashboardWidget>
    )
  }
}

CarePlanReviewRequestsWidget.propTypes = {
  teamId: PropTypes.number.isRequired,
}

const queryClient = new QueryClient()

function Provider(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <CarePlanReviewRequestsWidget {...props} />
    </QueryClientProvider>
  )
}

export default Provider
