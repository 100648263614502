import { useMemo } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import PropTypes from "prop-types"

import DataTable, { useDataTable } from "./DataTable"

import DashboardWidget from "~/views/shared/DashboardWidget"

const columnHelper = createColumnHelper()

function ActiveEnrolledChartsByDiagnosis({ data }) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("diagnosis", {
        header: "Diagnosis",
      }),
      columnHelper.accessor("count", {
        header: "Count",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("percent", {
        header: "Percent",
        cell: ({ getValue }) => `${getValue()}%`,
        enableColumnFilter: false,
      }),
    ],
    []
  )

  const table = useDataTable({ data, columns })

  return (
    <DashboardWidget title="Active, Enrolled Patients by Diagnosis">
      <div className="overflow-auto px-3" style={{ maxHeight: "385px" }}>
        <DataTable table={table} />
      </div>
    </DashboardWidget>
  )
}

ActiveEnrolledChartsByDiagnosis.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      diagnosis: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default ActiveEnrolledChartsByDiagnosis
