import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import PropTypes from "prop-types"

import { highlightMatchingStringSection } from "./helpers"

import FieldErrors from "~/design/forms/FieldErrors"
import { classNames } from "~/design/forms/selectBoxConfiguration"
import useErrorContext from "~/design/forms/useErrorContext"
import useNameContext from "~/design/forms/useNameContext"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function ChartSearch({ onChange }) {
  const { id, name } = useNameContext()
  const { hasError } = useErrorContext()

  const errorMessageId = `${id}Errors`

  async function fetchResults(searchString) {
    if (!searchString) return

    try {
      const charts = await api.chartSearches.get(searchString)

      return charts.map((chart) => ({
        value: chart,
        label: <ChartOption chart={chart} searchString={searchString} />,
      }))
    } catch {
      toast.error(`There was an error finding results for "${searchString}". Please check the CIN and try again.`)
    }
  }

  return (
    <>
      <AsyncSelect
        aria-errormessage={errorMessageId}
        aria-invalid={hasError}
        aria-label={`${name} search box`}
        aria-labelledby={`${id}Label`}
        className="react-select"
        classNamePrefix="react-select"
        classNames={classNames}
        id={`${id}SelectBox`}
        inputId={id}
        loadOptions={debounce(fetchResults, 400)}
        name={name}
        noOptionsMessage={() => "No member with matching CIN or name found"}
        placeholder="Start typing a member CIN or name"
        onChange={(event) => onChange(event?.value)}
      />
      <FieldErrors id={errorMessageId} />
    </>
  )
}

ChartSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
}

function ChartOption({ chart, searchString }) {
  return (
    <div>
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: highlightMatchingStringSection(`${chart.patientMedicaidMemberId} - ${chart.name}`, searchString),
          }}
        />
      </div>
      <div>
        {chart.healthHomeName} {">"} {chart.careManagementAgencyName}
      </div>
    </div>
  )
}

ChartOption.propTypes = {
  chart: types.chart.isRequired,
  searchString: PropTypes.string.isRequired,
}

export default ChartSearch
