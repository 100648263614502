import DOMPurify from "dompurify"

function highlightMatchingStringSection(string, match) {
  const sanitizedString = DOMPurify.sanitize(string)
  const regex = new RegExp(match, "gi")

  return sanitizedString.replace(regex, (str) => `<strong>${str}</strong>`)
}

export { highlightMatchingStringSection }
