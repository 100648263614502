import { redirectTo } from "~/browserHelpers"
import Form from "~/design/forms"
import { FileIcon } from "~/design/icons"
import { info_care_plan_path } from "~/routes"
import api from "~/services/api"
import types from "~/types"

function New({ carePlanSignature }) {
  const {
    patientCarePlanId,
    patientSignature,
    careManagerSignature,
    caregiverOrLegalRepSignature,
    caregiverOrLegalRepName,
    version,
    carePlan: { previewUrl, chartName, effectiveDate },
  } = carePlanSignature

  async function handleSubmit(values, { setErrors, setStatus }) {
    try {
      await api.carePlans.signatures.post(patientCarePlanId, values)
      redirectTo(info_care_plan_path(patientCarePlanId))
      setStatus("success")
    } catch ({ errors }) {
      setErrors(errors)
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="bg-light border rounded px-4 py-2 mb-3" style={{ maxWidth: "610px" }}>
        <h2 className="font-weight-bolder pb-2 mb-3 border-bottom">E-sign Care Plan</h2>
        <div className="bg-white border rounded p-2 mb-3">
          <FileIcon />
          &nbsp;
          <a href={previewUrl} target="_blank" rel="noopener noreferrer">
            {chartName} - {version} Care Plan (effective {effectiveDate})
          </a>
        </div>
        <Form
          initialValues={{
            patientSignature: patientSignature.url,
            careManagerSignature: careManagerSignature.url,
            caregiverOrLegalRepSignature: caregiverOrLegalRepSignature.url,
            caregiverOrLegalRepName,
            version,
          }}
          onSubmit={handleSubmit}
          vertical
          backgroundColor="light"
        >
          <Form.Group name="patientSignature">
            <Form.Label>Patient Signature</Form.Label>
            <Form.SignatureField />
          </Form.Group>
          <Form.Group name="careManagerSignature">
            <Form.Label>Care Manager Signature</Form.Label>
            <Form.SignatureField />
          </Form.Group>
          <Form.Group name="caregiverOrLegalRepSignature">
            <Form.Label>Caregiver/Legal Rep Signature</Form.Label>
            <Form.SignatureField />
          </Form.Group>
          <Form.Group name="caregiverOrLegalRepName">
            <Form.Label>Enter Caregiver/Legal Rep Name</Form.Label>
            <Form.TextField />
          </Form.Group>
          <Form.ActionBar
            right={
              <>
                <Form.CancelButton onClick={() => redirectTo(info_care_plan_path(patientCarePlanId))} />
                <Form.SubmitButton label="Save" />
              </>
            }
          />
        </Form>
      </div>
    </div>
  )
}

New.propTypes = {
  carePlanSignature: types.carePlanSignature.isRequired,
}

export default New
