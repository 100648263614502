import { Col as BootstrapCol, Form as BootstrapForm } from "react-bootstrap"
import { useField } from "formik"

import LinkButton from "~/design/buttons/LinkButton"
import FieldErrors from "~/design/forms/FieldErrors"
import HelpText from "~/design/forms/HelpText"
import useNameContext from "~/design/forms/useNameContext"
import ChartSearch from "~/views/shared/ChartSearch"

function NewMedicaidMemberId() {
  const { id, name } = useNameContext()
  const [field, , helpers] = useField({ name })

  const errorMessageId = `${id}Errors`

  return (
    <BootstrapCol sm={6}>
      {field.value ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            {field.value}
            <LinkButton onClick={() => helpers.setValue("")}>Remove</LinkButton>
          </div>
          <BootstrapForm.Text id="medicaidIdSearchBoxHelpText" muted>
            Click "Remove" to select a new CIN.
          </BootstrapForm.Text>
          <FieldErrors id={errorMessageId} />
        </>
      ) : (
        <>
          <ChartSearch onChange={(newChart) => helpers.setValue(newChart.patientMedicaidMemberId)} />
          <HelpText id="medicaidIdSearchBoxHelpText">
            Enter the value of the member's new medicaid member id. The new member must exist in FCM before this segment
            can be ended with the new CIN. If the member CIN cannot be found here, try creating the member first.
          </HelpText>
        </>
      )}
    </BootstrapCol>
  )
}

export default NewMedicaidMemberId
